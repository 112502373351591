
















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";
import Rules from "@/app/modules/_global/classes/Rules";

type Reservation = {
  id: number;
  type_id: number;
  resource_name: string;
  building_complex_city: string;
  building_name: string;
  date_from: string;
  date_to: string;
  time_from: string;
  time_to: string;
  info: string;
  status: {
    id: number;
  };
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    ReservationEditForm: () => import("@/app/modules/booking/components/ReservationEditForm.vue")
  }
})
export default class EditReservationPage extends Vue {
  @Prop(Number) readonly id!: number;

  @Watch("$route.query")
  onRouteQueryChanged() {
    this.fetchReservation();
  }

  valid: boolean = true as boolean;
  errors: object = {} as object;

  formData: Reservation | null = null;
  cancelDialog = false;

  rules = {
    required: Rules.required,
    date: Rules.date
  };

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView(`Edit reservation ${this.id}`);
  }

  beforeDestroy() {
    this.scrollTop();
  }

  created() {
    this.fetchReservation();
  }

  get appData() {
    return this.$store.getters["app/data"];
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  fetchReservation() {
    const data = {
      person_id: this.userData.user.id,
      reservation_id: this.id
    };

    this.$store.dispatch("booking/fetchReservation", data).then(({ data }) => {
      this.formData = { ...data };
    });
  }

  cancel() {
    this.$store
      .dispatch("booking/cancelReservation", this.formData?.id)
      .then(() => this.$router.push({ name: "booking.reservations" }));
  }

  update() {
    const data = {
      id: this.formData?.id,
      body: {
        person_id: this.userData.user.id,
        status_id: this.formData?.status.id,
        date_from: this.formData?.date_from,
        date_to: this.formData?.date_to,
        time_from: this.formData?.time_from,
        time_to: this.formData?.time_to,
        info: this.formData?.info
      }
    };

    this.$store
      .dispatch("booking/updateReservation", data)
      .then(() => this.$router.push({ name: "booking.reservations" }))
      .catch(error => (this.errors = error.response.data.errors));
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
